.content-list1-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.content-list1-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.content-list1-content {
  align-self: stretch;
  align-items: flex-start;
}
.content-list1-ul {
  align-items: flex-start;
}
.content-list1-text {
  display: inline-block;
}
.content-list1-text01 {
  display: inline-block;
}
.content-list1-text02 {
  display: inline-block;
}
.content-list1-text03 {
  display: inline-block;
}
.content-list1-text04 {
  display: inline-block;
}
.content-list1-text05 {
  display: inline-block;
}
.content-list1-text06 {
  display: inline-block;
}
.content-list1-text07 {
  display: inline-block;
}
.content-list1-text08 {
  display: inline-block;
}
.content-list1-text09 {
  display: inline-block;
}
.content-list1-text10 {
  display: inline-block;
}
.content-list1-text11 {
  display: inline-block;
  align-self: flex-start;
}
